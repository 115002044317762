import { OptimizedImage } from "components/OptimizedImage";
import { ClintButton, ClintButtonType } from "components/shadcn-ui/button";
import vector from "public/images/vector.png";
import { cn } from "utils/utils";
import { Headline } from "./Headline";
import { Label } from "./Label";
import { Paragraph } from "./Paragraph";
import { Tag } from "./Tag";

export const CardSolutions = ({
  bgImg,
  tags = [],
  title,
  children,
  className,
  button,
  alt,
  preTitle = "Pour les",
  id,
}: {
  tags?: string[];
  title: string;
  children?: React.ReactNode;
  className?: string;
  bgImg: string;
  alt: string;
  button: ClintButtonType;
  preTitle?: string;
  id?: string;
}) => {
  return (
    <div
      id={id}
      className={cn(
        "rounded-clint flex w-fit lg:basis-[622px] bg-white flex-col lg:flex-row gap-1.5 overflow-hidden",
        className,
      )}
    >
      <OptimizedImage
        source={bgImg}
        height={372 * 3}
        width={330 * 3}
        maxWidth={330 * 3}
        className=""
        alt={alt}
        imageProps={{
          minHeight: "auto",
          objectFit: "cover",
        }}
        containerClassName="overflow-hidden max-h-[330px] relative lg:basis-[290px] mx-0 overflow-hidden"
      >
        <div className="absolute inset-0 z-20 flex h-full w-full items-center justify-center">
          <img src={vector} className="" alt="cercle GoodCollect" />
        </div>
        <div
          style={{
            background: `linear-gradient(rgba(17, 41, 75, 0.4),
                       rgba(17, 41, 75, 0.4))`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className={`absolute inset-0 z-20 flex flex-1 grow flex-col justify-between px-5 pb-5 pt-[31px]`}
        >
          <div className="flex flex-col">
            {preTitle ? (
              <Label size={"S"} as="p" className="text-gray-200">
                {preTitle}
              </Label>
            ) : null}
            <Headline size={"h3"} as="div" className="text-white">
              {title}
            </Headline>
          </div>
          {tags.length > 0 ? (
            <div className="flex flex-wrap gap-1.5">
              {tags.map((tag, index) => (
                <Tag key={index} type="Rounded" variant="Light">
                  {tag}
                </Tag>
              ))}
            </div>
          ) : null}
        </div>
      </OptimizedImage>

      {/* </div> */}
      <div className="mt-auto flex h-fit w-full lg:basis-[290px] flex-col justify-end gap-6 px-2 lg:px-5 p-5 pb-4">
        <Paragraph size={"sm"} fontWeight={"regular"} variant={"secondary"}>
          {children}
        </Paragraph>
        <ClintButton {...button} />
      </div>
    </div>
  );
};

export const CardSolutionsWide = ({
  bgImg,
  title,
  children,
  className,
  button,
  alt,
  preTitle = "Pour les",
  id,
}: {
  title: string;
  children?: React.ReactNode;
  className?: string;
  bgImg: string;
  alt: string;
  button: ClintButtonType;
  preTitle?: string;
  id?: string;
}) => {
  return (
    <div
      id={id}
      className={cn(
        "rounded-clint flex w-fit bg-white flex-wrap gap-1.5 overflow-hidden relative",
        className,
      )}
    >
      <OptimizedImage
        source={bgImg}
        height={461 * 2}
        width={668 * 2}
        maxWidth={668 * 2}
        alt={alt}
        imageProps={{
          minHeight: "100%",
          objectFit: "cover",
        }}
        containerClassName="max-h-full overflow-hidden relative mx-0"
      >
        <div className="pointer-events-none absolute inset-0 z-20 flex h-full w-full items-center justify-center">
          <img src={vector} className="" alt="cercle GoodCollect" />
        </div>
        <div
          style={{
            background: `linear-gradient(rgba(17, 41, 75, 0.4),
                       rgba(17, 41, 75, 0.4))`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className={`absolute inset-0 z-20 flex grow flex-col px-5 pb-5 pt-[31px]`}
        >
          <div className="flex flex-col h-full">
            {preTitle ? (
              <Label size={"S"} as="p" className="text-gray-200">
                {preTitle}
              </Label>
            ) : null}
            <Headline size={"h3"} as="div" className="text-white">
              {title}
            </Headline>

            <div className="mt-auto flex h-fit w-full flex-col gap-6 max-w-[60ch]">
              <Paragraph size={"sm"} fontWeight={"bold"} variant={"white"}>
                {children}
              </Paragraph>
              <ClintButton {...button} />
            </div>
          </div>
        </div>
      </OptimizedImage>
    </div>
  );
};
